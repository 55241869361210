import logoGitea from './Gitea_Logo.svg';
import logo from './Android_robot.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://cfif31.ru:3000">
          <img src={logoGitea} className="Gitea-logo" alt="logo" />
        </a>
        <a href="https://cfif31.ru/mobile">
          <img src={logo} className="Android-logo" alt="logo" />
        </a>
        
        
      </header>
    </div>
  );
}

export default App;
